.mt-12 {
  margin-top: 3rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.m-0 {
  margin: 0;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

