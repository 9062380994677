/*======================================
 6. Home-style-3 
 =======================================*/


/*--------------------------------------------------------------
6.1 wpo-wedding-date
--------------------------------------------------------------*/
.wpo-wedding-date {
	text-align: center;
    padding-bottom: 0;

	.react-countdown {
		overflow: hidden;
		text-align: center;
		display: inline-block;
		margin-top: 20px;

		> .time-section {
			width: 180px;
			height: 163px;
			@include background-style("../../images/clock-bg.png", center center, cover, no-repeat, local);
			float: left;
			display: flex;
			justify-content: center;
			flex-direction: column;

			@include media-query(1200px) {
				width: 144px;
                height: 140px;
			}
			@include media-query(991px) {
				width: 120px;
				height: 110px;
			}

			@include media-query(767px) {
				width: calc(50% - 20px);  
				margin: 10px;
				padding-bottom: 15px;
                height: 110px;
			}

			> div {
				display: table-cell;
				vertical-align: middle;
			}

			&:after{
			   display: none;	
			}
		}

		> .time-section + .time-section {
			margin-left: 50px;

			@include media-query(991px) {
				margin-left: 20px;
			}

			@include media-query(767px) {
				margin-left: 10px;
			}
		}

		.time {
			font-family: $heading-font;
			font-size: 54px;
			font-size: calc-rem-value(54);
			line-height: 1em;
			padding-top: 0px;
            color: #5880a2;


            @include media-query(1200px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
			}

			@include media-query(991px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
			}
		}

        .time-text{
            color: #365872;
			font-size: 16px;
        }
	}
}


/*--------------------------------------------------------------
6.2 couple-section
--------------------------------------------------------------*/
.couple-section {

	.couple-area > div {
		float: left;

		@include media-query(991px) {
			float: none;
		}
	}

	.middle-couple-pic {
		width: 470px;
        position: relative;
       height: 690px;

	   @include media-query(1399px) {
			width: 350px;
			height: 510px;
		}
	   @include media-query(1199px) {
			width: 270px;
			height: 380px;
		}



        .frame-img{
            position: absolute;
			left: -54px;
			top: -54px;
			width: 130%;
			height: 130%;

			@include media-query(1199px) {
				left: -33px;
				top: -30px;
				width: 124%;
			}
	
        }

		img {
            border-radius: 235px;
		}

		@include media-query(991px) {
			margin: 50px auto;
		}
	}

	.text-grid {
		width: 29%;
		padding: 140px 80px 40px;

		@include media-query(1399px) {
			width: 34%;
			padding: 70px 80px 40px;
		}
		@include media-query(1199px) {
			padding: 10px 56px 40px;
		}

		@include media-query(991px) {
			width: 100%;
			padding: 0;
			text-align: center;
		}

		h3 {
			font-size: 22px;
			font-size: calc-rem-value(22);
			margin: 0 0 0.8em;
		}

        .couple-img{
            margin-bottom: 20px;
            width: 100px;
            height: 100px;
            margin-right: auto;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            &:before{
                position: absolute;
                left: 5px;
                top: 5px;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                content: "";
                border:1px solid $white;
                z-index: 1;
            }
            img{
                border-radius: 50%;
                transform: scale(1);
                transition: all .3s;

            }

			@media(max-width:991px){
              margin: 0 auto;
			  margin-bottom: 20px;
			}
        }
	}

	.bride {
		padding-left: 0;
		text-align: right;

		@include media-query(991px) {
			text-align: center;
			margin: 0 auto;
		}

		.social {
			float: right;

			@include media-query(991px) {
				float: none;
				display: flex;
				justify-content: center;
			}
		}
        .couple-img{
            margin: 0;
            margin-bottom: 20px;
            margin-left: auto;

			@include media-query(991px) {
				text-align: center;
				margin: 0 auto;
				margin-bottom: 20px;
			}
	
        }
	}

	.groom {
		padding-right: 0;
	}

	ul {
		list-style: none;
		overflow: hidden;
		padding-top: 15px;

		@include media-query(991px) {
			padding-top: 10px;
			display: flex;
			justify-content: center;
		}

		li {
			float: left;
		}

		> li + li {
			margin-left: 25px;
		}

		li a {
			display: block;
			text-align: center;
			color: $theme-primary-color-s2;

			&:hover {
				color: $theme-primary-color;
			}
		}
	}
}


/*--------------------------------------------------------------
6.3 wpo-video-section-s2
--------------------------------------------------------------*/

.wpo-video-section-s2{
    padding-top: 0;
    &:before{
        display: none;
    }
}


/*--------------------------------------------------------------
6.4 story-section
--------------------------------------------------------------*/
.story-section {

	.story-timeline {
		position: relative;

		&:after {
			content: "";
			background: #cee0ef;
			width: 2px;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 0;
			@include translatingX();

			@include media-query(991px) {
				display: none;
			}
		}

        .round-shape{
			background: $white;
			width: 20px;
			height: 20px;
			position: absolute;
			left: 50%;
			top: 0;
            border: 1px solid $theme-primary-color-s2;
			@include translatingX();
            border-radius: 50%;
            z-index: 1;

            &:before{
                position: absolute;
                left: 2px;
                top: 2px;
                width: 14px;
                height: 14px;
                content: "";
                background: $theme-primary-color-s2;
                border-radius: 50%;
            }

			@include media-query(991px) {
				display: none;
                &:before{
                    display: none;
                }
			}
        }

        .img-holder{
            max-width: 330px;
            padding: 10px;
            border: 1px dashed $theme-primary-color;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            background: $white;
            margin: 0 auto;

            &:before{
                position: absolute;
                left: -103px;
                top: 195px;
                width: 90%;
                height: 90%;
                border: 1px dashed $theme-primary-color-s2;
                content: "";
                border-radius: 50%;
                z-index: -1;
                border-bottom: 0;
                border-right: 0;
                border-top: 0;
                transform: rotate(66deg);

                @media(max-width:1439px){
                    left: -140px;
                }
                @media(max-width:1399px){
                    left: -96px;
                }
                @media(max-width:1199px){
                    left: -65px;
                }
                @media(max-width:991px){
                    display: none;
                }
            }

            &.left-site{

                @media(max-width:991px){
                   margin-bottom: 40px;
                }
                &:before{
                    position: absolute;
                    left: auto;
                    right: -102px;
                    transform: rotate(102deg);

                    @media(max-width:1439px){
                        right: -140px;
                    }
                    @media(max-width:1399px){
                        right: -96px;
                    }
                    @media(max-width:1199px){
                        right: -65px;
                    }
                }
            }
            img{
                border-radius: 50%;
            }

            .story-shape-img{
                position: absolute;
                bottom: -45px;
                left: 55px;            
                img{
                    border-radius: 0;
                }
            }
        }
	}

	.story-timeline > .row {
		position: relative;

		.horizontal-line {
			background: transparentize($theme-primary-color, 0.9);
			width: 70%;
			height: 1px;
			position: absolute;
			left: 50%;
			top: 0;
			@include translatingX();
		}

        &:last-child{
            .col{
                padding-top: 0!important;
            }

			@media(max-width:991px){
              padding: 0!important;
			  margin: 0!important;
			}
        }
	}

	.story-timeline > .row + .row {
		margin-top: 50px;
		padding-top: 15px;

		@include media-query(991px) {
			padding-top: 30;
		}
	}

	.story-timeline > .row > .col:first-child {
		padding: 0 45px 0 100px;
		
		@include media-query(1199px) {
			padding: 0 45px 0 70px;
		}

		@include media-query(991px) {
			padding: 0 15px;
		}
	}

	.story-timeline > .row > .col:last-child {
		padding: 0 100px 0 45px;
		
		@include media-query(1199px) {
			padding: 0 70px 0 45px;
		}

		@include media-query(991px) {
			padding: 0 15px;
		}
	}

	.story-timeline .story-text {
        padding-left: 50px;
		@include media-query(991px) {
			background: transparentize($theme-primary-color, 0.95);
			padding: 35px 25px;
            text-align: center;
		}

	}
	.story-timeline .left-site .story-text {
        padding-left: 0px;
        padding-right: 50px;
        @include media-query(991px) {
			background: transparentize($theme-primary-color, 0.95);
			padding: 35px 25px;
            text-align: center;
		}
		
	}

	.story-timeline .right-align-text {
		@include widther(992px) {
			text-align: right;
		}
	}
	.story-timeline .story-text.right-align-text {
		@include widther(992px) {
			padding-left: 0;
			margin-right: 50px;
		}
	}

	.story-timeline .text-holder {
		padding-top: 45px !important;
		position: relative;

		@include media-query(991px) {
			border: 0;
			padding-top: 0 !important;
		}

		.heart {
			background-color: #e7eff6;
			width: 43px;
			height: 43px;
			line-height: 41px;
			text-align: center;
			position: absolute;
			left: -22px;
			top: -22px;	
			@include rounded-border();
			z-index: 10;

			@include media-query(991px) {
				display: none;
			}
		}

		.fi {
			color: $theme-primary-color-s2;
		}
	}

	.story-timeline .right-heart .heart {
		left: auto;
		right: -22px;
	}

	.story-timeline h3 {
		font-size: 22px;
		font-size: calc-rem-value(22);
		font-weight: 500;
		color: $dark-gray;
		margin: 0 0 0.5em;
		text-transform: uppercase;

		@include media-query(991px) {
			font-size: 20px;
			font-size: calc-rem-value(20);
		}

		@include media-query(767px) {
			font-size: 18px;
			font-size: calc-rem-value(18);
		}
	}

	.story-timeline .date {
		font-size: 14px;
		font-size: calc-rem-value(14);
		font-weight: 500;
		display: block;
		margin-bottom: 15px;
		color: #8ab1d2;
	}

	.story-timeline p{
		margin-bottom: 0;
	}
	.story-timeline img {
		display: inline-block;
	}
}


/*--------------------------------------------------------------
6.5 wpo-portfolio-section-s2
--------------------------------------------------------------*/

.wpo-portfolio-section-s2,
.wpo-portfolio-section-s4{
    padding: 0 60px;

	@media(max-width:767px){
		padding: 0;
	}
    .portfolio-grids{
        .grid{
			img{
				max-height: 460px;
			}
            &:first-child{
				width: 50%;

				@include media-query(1200px) {
					width: 100%;
				}
			}
            &:last-child{
				width: 50%;

				@include media-query(1200px) {
					width: 100%;
				}
			}

			.img-holder{
				&:before{
					display: none;
				}

				.react-fancybox{
					.thumbnail{
						&:before{
							position: absolute;
							left: 2%;
							top: 2%;
							width: 96%;
							height: 96%;
							content: "\e61a";
							background: rgba(255,255, 255, 0.8);
							opacity: 0;
							transition: all .3s;
							transform: scale(0);
							font-family: 'themify';
							display: flex;
							justify-content: center;
							flex-direction: column;
							text-align: center;
							font-size: 30px;
    						color: #002642;
							cursor: pointer;
						}

						&:hover{
							&:before{
								opacity: 1;
								transform: scale(1);
							}
						}

						img{
							max-width: 100%;
						}
						
					}
				}
				.close-btn{
					width: unset;
					cursor: pointer;
				}
			}
        }
    }
}

.wpo-portfolio-section-s4{
	padding: 120px 60px 110px;

	@media(max-width:991px){
		padding: 90px 40px 80px;
	}

	@media(max-width:767px){
		padding: 70px 0px 60px;
	}
	.portfolio-grids{
        .grid{
			img{
				max-height: 460px;
			}
           
        }
    }
}



/* 6.6 wpo-cta-section-s3 */

.wpo-cta-section-s3{
	background: url(../../images/cta/bg-3.jpg) no-repeat center center;

	.wpo-cta-item{
		h2{
			margin-bottom: 10px;
			margin-top: 0;
		}

		p{
			font-size: 22px;
			color: $white;
			margin-bottom: 30px;

			@media(max-width:767px){
				font-size: 16px;
				margin-bottom: 15px;
			}
			@media(max-width:575px){
				font-size: 14px;
			}
		}
	}
}



/* 6.7 wpo-event-section */

.wpo-event-section{
	padding-bottom: 90px;

	@media(max-width:767px){
       padding-bottom: 70px;
	}
  .wpo-event-wrap{
	  .wpo-event-item{
		  padding: 20px;
		  border: 2px solid #b0c7d9;
		  position: relative;
		  margin-bottom: 40px;
		.wpo-event-text{
			padding: 40px 35px;
			text-align: center;
			background: $section-bg-color;
			@media(max-width:1200px){
				padding: 25px 15px;
			}

			h2{
				font-size: 25px;
				text-transform: uppercase;
				border-bottom: 1px solid #c4d8e4;
				padding-bottom: 30px;
				margin-bottom: 40px;
			}
  
			ul{
				list-style: none;

				li{
					margin-bottom: 15px;
					font-size: 18px;
					line-height: 30px;

					&:last-child{
						margin-bottom: 0;
					}

					button{
						font-weight: 700;
						color: #8facc2;
						position: relative;
						display: inline-block;
						margin-top: 20px;
						text-transform: capitalize;
						font-size: 16px;

						&:before{
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							height: 1px;
							content: "";
							background: #8facc2;
						}

						&:focus{
							outline: none;
							box-shadow: none;
						}

						&:hover{
							background: none;
						}
					}
				}
			}
		}

		.event-shape-1{
			position: absolute;
			right: -5px;
    		top: -20px;
			background: $white;
			padding: 5px;
		}
		.event-shape-2{
			position: absolute;
			left: -5px;
    		bottom: -20px;
			background: $white;
			padding: 5px;
		}
	  }
	  .row>* {
		padding-right: calc(var(--bs-gutter-x)/ 1);
		padding-left: calc(var(--bs-gutter-x)/ 1);

		@media(max-width:1200px){
			padding-right: calc(var(--bs-gutter-x)/ 2);
			padding-left: calc(var(--bs-gutter-x)/ 2);
		}
	}
  }

}


.MuiDialog-paperWidthSm{
  max-width: 900px!important;
}
.quickview-dialog{
	z-index: 999999!important;
}
.modal-body{
	iframe{
		width: 800px;
		height: 500px;

		@media(max-width:991px){
          width: 500px;
		}
		@media(max-width:600px){
          width: 100%;
		}
	}
}

.MuiDialogTitle-root{
	padding-bottom: 0!important;
}

/* 6.8 wpo-partners-section-s3 */

.wpo-partners-section-s3{
	background: $section-bg-color;
}



/* 6.9 wpo-blog-section-s2 */

.wpo-blog-section-s3{
    padding-top: 120px;

    @media(max-width:991px){
      padding-top: 90px;
    }
}