

/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/

/*3.1 wpo-hero-slider*/

.wpo-hero-slider,
.static-hero,
.wpo-hero-slider-s2,
.wpo-hero-slider-s3 {
	width: 100%;
	height: 900px;
	display: flex;
	position: relative;
	z-index: 0;

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}


	.swiper-container,
	.hero-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.hero-slide{
		height: 900px;

		@include media-query(991px) {
			height: 600px;
		}
	
		@include media-query(767px) {
			height: 500px;
		}
	
	}

	.slide-inner,
	.hero-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		// background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;

		&:before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #00315f;
			content: "";
			opacity: .25;
			z-index: -1;
		}

		.slide-content{
			padding: 60px 70px;
			max-width: 1020px;
			margin: 0 auto;
			text-align: center;
			background: rgba(134,160,182,.3);
			position: relative;

			@include media-query(1199px) {
				max-width: 800px;
			}

			@include media-query(991px) {
				max-width: 530px;
			}
		
			@include media-query(767px) {
				max-width: 385px;
				padding: 80px 30px;
			}

			@include media-query(575px) {
				padding: 30px 20px;
			  }
		

			&:before{
				position: absolute;
				left: -40px;
				top: 0;
				height: 100%;
				content: "";
				width: 60px;
				background: url(../../images/slider/shape.png) no-repeat center center;

				@include media-query(575px) {
				  display: none;
				}
			}
			&:after{
				position: absolute;
				right: -40px;
				top: 0;
				height: 100%;
				content: "";
				width: 60px;
				background: url(../../images/slider/shape2.png) no-repeat center center;

				@include media-query(575px) {
				  display: none;
				}
			}
		}
	}

	.swiper-container,
	.hero-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.slick-prev,
	.slick-next {
		background-color: transparentize($theme-primary-color, 0.3);
		width:45px;
		height: 45px;
		z-index: 10;
		@include rounded-border(50%);
		opacity: 0;
		visibility: hidden;
		transition: all .3s;
		border: 2px solid $theme-primary-color;

		&:hover{
			background-color: $theme-primary-color;
		}
	}

	.slick-prev {
		left: 0px;

		@include media-query(767px) {
			display: none !important;
		}

		&:before {
			font-family: "themify";
			content: "\e629";
			opacity: 1;
		}
	}

	.slick-next {
		right: 0px;

		@include media-query(767px) {
			display: none !important;
		}

		&:before {
			font-family: "themify";
			content: "\e628";
			opacity: 1;
		}
	}

	&:hover{

		.slick-next {
			right: 20px;
			opacity: 1;
			visibility: visible;
		}

		.slick-prev {
			left: 20px;
			opacity: 1;
			visibility: visible;
		}
	}
}


.wpo-hero-slider,
.static-hero,
.wpo-hero-slider-s2{

	@include media-query(1199px) {
		height: 680px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}


	.gradient-overlay {
		@include linear-gradient-bg(left, $dark-gray 45%, transparent 65%);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		opacity: .6;
	}

	.gradient-overlay + .container {
		position: relative;
		z-index: 11;
	}

	.swiper-slide {
		position: relative;
		z-index: 11;
	}

	.wpo-hero-title-top{
		span{
			font-size: 22px;
			color: #e4e4e4;
			font-family: $heading-font;

			@include media-query(767px) {
				font-size: 15px;
			}
	
		}
	}

	.slide-title {
		h2 {
			font-size: 100px;
			font-weight: 500;
			line-height: 60px;
			margin:10px 0 35px;
			color: $white;
			text-transform: uppercase;

			@include media-query(1199px) {
				font-size: 50px;
				font-size: calc-rem-value(50);
			}

			@include media-query(991px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
				line-height: 55px;
				margin-bottom: 20px;
			}

			@include media-query(767px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
				line-height: 36px;
			}
		}
	}

	.slide-text {
		
		p {
			font-size: 30px;
			color: $white;
			line-height: 45px;
			max-width: 780px;
			color: $white;
			margin: 0 auto;
			margin-bottom: 30px;


			@include media-query(991px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
				line-height: 30px;
				margin-bottom: 25px;
			}

			@include media-query(767px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
				line-height: 22px;
				margin-bottom: 30px;
			}
		}		
	}


	.slide-btns {
		@include media-query(991px) {
			max-width: 400px;
		}

		.theme-btn{
			border-radius: 0;
			font-weight: 600;
			font-size: 18px;

			&:after{
				border-radius: 0;
			}
		}
	}


	.slide-btns .hero-video-btn {
		margin-left: 40px;

		@include media-query(767px) {
			margin-left: 20px;
		}
	}
}

// wpo-hero-slider-s2

.wpo-hero-slider-s2{

	height: 900px;

	@include media-query(1199px) {
		height: 680px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}

	.hero-inner,
	.slide-inner{
		.slide-content {
		   max-width: 760px;
		   position: relative;
		   background: none;
		   padding: 80px 70px;

		   @media(max-width:991px){
			max-width: 650px;
		  }
		   @media(max-width:767px){
			max-width: 450px;
			padding: 40px 50px;
		  }

		   .border-1{
			   position: absolute;
			   left: 0;
			   top: 0;
			   width: 67%;
			   height: 1px;
			   background: $white;

			   @media(max-width:767px){
				  width: 44%;
			    }
				@media(max-width:450px){
					width: 36%;
				}
				@media(max-width:400px){
					width: 30%;
				}
				@media(max-width:370px){
					width: 15%;
				 }
		   }
		   .border-2{
			   position: absolute;
			   right: 0;
			   bottom: 0;
			   width: 67%;
			   height: 1px;
			   background: $white;
			   @media(max-width:767px){
				width: 44%;
			  }
			   @media(max-width:450px){
				width: 36%;
			  }
			  @media(max-width:400px){
					width: 30%;
				}
			  @media(max-width:370px){
				 width: 15%;
			  }
		   }
		   .border-3{
			   position: absolute;
			   right: 0;
			   bottom: 0;
			   height: 70%;
			   width: 1px;
			   background: $white;
			   @media(max-width:1199px){
                 height: 67%;
			   }

			   @media(max-width:767px){
				height: 41%;
			  }
		   }
		   .border-4{
			   position: absolute;
			   left: 0;
			   top: 0;
			   height: 70%;
			   width: 1px;
			   background: $white;
			   @media(max-width:1199px){
                 height: 67%;
			   }
			   @media(max-width:767px){
				height: 41%;
			  }
		   }

		   .s-img-1{
			   position: absolute;
			   top: -55px;
			   right: 0;
		   }
		   .s-img-2{
			   position: absolute;
			   bottom:-54px;
			   left: 0;
		   }

		   &:before,&:after{
			   display: none;
		   }

		   h2{
			   text-transform: capitalize;

			   @media(max-width:1199px){
                    margin-bottom: 10px;
			   }
		   }

		   p{
			   margin-bottom: 0;
		   }
		}
	}
	
}



/*-------------------------------------------
	hero style 1
--------------------------------------------*/
.static-hero {
	background: $section-bg-color;
	height: 830px;
	display: flex;
	position: relative;
	z-index: 0;
	overflow: hidden;

	.hero-inner{
		&::before{
			display: none;
		}
	}

	@include media-query(1199px) {
		height: 680px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 400px;
	}

	.slide-title{
       padding-top: 35px;

	   @media(max-width:1440px){
		padding-top: 0;
	 }

	   h2{
		 font-size: 60px;  
		 color: $dark-gray;
		 font-weight: 600;
		 line-height: 80px;
		 margin-bottom: 15px;

		 @media(max-width:1750px){
			font-size: 50px;
			line-height: 65px;
			margin-bottom: 10px;
		 }
		 @media(max-width:1440px){
			font-size: 40px;
			line-height: 55px;
			margin-bottom: 10px;
		 }
		 @media(max-width:767px){
			font-size: 35px;
			line-height: 43px;
			margin-bottom: 10px;
		 }

		 span{
			 color: $theme-primary-color-s2;
		 }
	   }
	}

	.wpo-static-hero-inner{
		padding-left: 70px;

		@include media-query(1750px) {
			padding-left: 20px;
		}
		@include media-query(767px) {
			padding-left: 0px;
		}

	}

	.slide-text p{
		font-size: 22px;
		color: #737373;
		@include media-query(1400px) {
			max-width: 600px;
			margin: 0;
			margin-bottom: 30px;
		}

		@media(max-width:767px){
			font-size: 16px;
		 }
	}
	

	.static-hero-slide-img {
		position: relative;
		right: 0px;
		max-width: 1130px;

		img{
			border: 2px solid $white;

			@include media-query(991px) {
				object-fit: cover;
			}

		}

		@include media-query(1600px) {
			right: 0;
		}
        

		@include media-query(767px) {
			display: none;
		}

		.owl-controls {
			width: 100%;
			margin: 0;
			position: absolute;
			left: 0;
			top: 50%;
			@include translatingY();
		}
	
		.owl-nav [class*=owl-] {
			padding: 0;
			margin: 0;
			color: $theme-primary-color;
			@include transition-time(0.3s);
			border: 0;
			background: none;
	
			.fi{
				&::before{
					font-size: 20px;
					color: $theme-primary-color-s2;
				}
			}
		}
	
		.owl-nav .owl-prev,
		.owl-nav .owl-next {
			position: absolute;
			top: 50%;
			@include translatingY();
			background: $white;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			opacity: 0;
			transition: all .3s;
	
			@media(max-width:575px){
				display: none;
			}
		}
	
		.owl-nav .owl-prev {
			left: 15px;
		}
	
		.owl-nav .owl-next {
			right: 15px;
		}
	
		.owl-dots {
			text-align: center;
			margin-top: 20px;
	
			button{
				width: 10px;
				height: 12px;
				border: 0;
				background: #d4e6f5;
				margin-right: 10px;
				border-radius: 50%;
			}
			.owl-dot.active{
			  background-color: $theme-primary-color;
			}
		}

		&:hover{
			.owl-nav .owl-prev,
			.owl-nav .owl-next {
				opacity: 1;

			}
		}
	}

}

@-webkit-keyframes spineer {
	from {
	  -webkit-box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
	  box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
	}
	to {
	  -webkit-box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
	  box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
	}
  }
  
  @keyframes spineer {
	from {
	  -webkit-box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
	  box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
	}
	to {
	  -webkit-box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
	  box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
	}
  }




/*-------------------------------------------
	static-hero-s2
--------------------------------------------*/
.static-hero-s2{
	background: $section-bg-color;
	height: 900px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@include media-query(1399px) {
		height: 720px;
	}
	@include media-query(1199px) {
		height: 680px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 700px;
	}

	.hero-inner{
		padding-top: 120px;
		.row{
			justify-content: center;
		}
	}


	.couple-area > div {
		float: left;

		@include media-query(991px) {
			float: none;
		}
	}

	.middle-couple-pic {
		width: 651px;
        position: relative;
		height: 740px;
		bottom: -60px;

	   @include media-query(1399px) {
			width: 550px;
			height: 510px;
			bottom: 30px;
		}
	   @include media-query(1199px) {
			width: 492px;
			height: 520px;
			bottom: -16px;
		}

		@include media-query(991px) {
			display: none;
		}
	}

	.text-grid {
		width: 20%;
		padding: 140px 80px 40px;

		@include media-query(1399px) {
			width: 25%;
			padding: 70px 80px 40px;
		}
		@include media-query(1199px) {
			padding: 10px 56px 40px;
		}
		@include media-query(991px) {
			width: 40%;
		}

		@include media-query(767px) {
			width: 100%;
			padding: 0;
			text-align: center;
		}

		h3 {
			font-size: 22px;
			font-size: calc-rem-value(22);
			margin: 0 0 0.8em;
		}
	}

	.bride {
		padding-left: 0;
		text-align: right;

		@include media-query(767px) {
			text-align: center;
			margin: 0 auto;
			margin-bottom: 30px;
		}

		.couple-img{
			margin-bottom: 20px;
		}

		.social {
			float: right;

			@include media-query(767px) {
				float: none;
				display: flex;
				justify-content: center;
			}
		}
	}

	.groom {
		padding-right: 0;

		.couple-img{
			margin-bottom: 20px;
		}
	}

	ul {
		list-style: none;
		overflow: hidden;
		padding-top: 15px;

		@include media-query(767px) {
			padding-top: 10px;
			display: flex;
			justify-content: center;
		}

		li {
			float: left;
		}

		> li + li {
			margin-left: 25px;
		}

		li a {
			display: block;
			text-align: center;
			color: $theme-primary-color-s2;

			&:hover {
				color: $theme-primary-color;
			}
		}
	}

	.flower-shape-1{
		position: absolute;
		left: 0;
		z-index: -1;
		top: 0;

		img{
			@media(max-width:1399px){
                width: 70%;
			}
		}
	}
	.flower-shape-2{
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
		text-align: right;

		img{
			@media(max-width:1399px){
                width: 70%;
			}
		}
	}
}

// wpo-hero-style-3


.wpo-hero-style-3{
	position: relative;
	height: 980px;

	.hero-slide{
		height: 980px;
	}

	@include media-query(1399px) {
		height: 720px;
	}
	@include media-query(1199px) {
		height: 680px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 800px;
	}


	.hero-slide{
		height: 980px;

		@include media-query(1399px) {
			height: 720px;
		}
		@include media-query(1199px) {
			height: 680px;
		}
	
		@include media-query(991px) {
			height: 600px;
		}
	
		@include media-query(767px) {
			height: 800px;
		}
	}

	.slide-inner:before{
		opacity: .3;
	}
	.wedding-announcement {
        padding-top: 75px;
		@include widther(992px) {
			width: 80%;
			left: 10%;
		}

		width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    z-index: 2;
	    text-align: center;
	    display: table;

		.couple-text {
			width: 100%;
			display: table-cell;
			vertical-align: middle;

			h2{
				font-size: 100px;
				font-weight: 500;
				color: $white;

				@media(max-width:767px){
					font-size: 50px;
				}
				@media(max-width:575px){
					font-size: 40px;
				}
			}

			p{
				font-size: 30px;
				font-family: $heading-font;
				color: $white;
				font-weight: 500;
				@media(max-width:767px){
					font-size: 18px;
				}
			}
		}
	}
	.wpo-wedding-date{
		padding-top: 30px;
		@media(max-width:575px){
			padding-top: 10px;
		}
		.react-countdown{
			@media(max-width:700px){
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
			> .time-section {
				width: 180px;
				height: 163px;
				@include background-style("../../images/clock-bg2.png", center center, cover, no-repeat, local);
				float: left;
				display: flex;
				justify-content: center;
				flex-direction: column;
				padding-bottom: 0;

				@media(max-width:1199px){
					width: 120px;
					height: 120px;
				}

				&:last-child {
					@media(max-width:767px){
						right: 0;
					}
				}
			}
			.time-section + .time-section {
				@media(max-width:1399px){
					margin-left: 10px;
				}
			}

			.time{
				color: $white;
				font-size: 50px;

				@include media-query(1199px) {
					font-size: 30px;
				}
			}

			.time-text{
				color: $white;
				font-size: 18px;
				@include media-query(1199px) {
					font-size: 16px;
				}
			}
		}
	}
}


/*-------------------------------------------
	static-hero-s3
--------------------------------------------*/
.static-hero-s3,
.static-hero-s4{
  height: 100vh;
  min-height: 920px;
  background: url(../../images/slider/slide-10.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;


	@include media-query(550px) {
		min-height: 750px;
	}

	@include media-query(450px) {
		min-height: 550px;
	}

	.hero-inner{
		padding-top: 120px;
		.row{
			justify-content: center;
		}
	}


  .wpo-event-item{
	padding: 20px;
	position: relative;
	margin-bottom: 40px;
	background: url(../../images/slider/frame.png) no-repeat center center;
	width: 530px;
    height: 730px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 0 auto;
	background-size: contain;

	@media(max-width:550px){
		width: 420px;
	}
	@media(max-width:450px){
		width: 310px;
	}
	
  .wpo-event-text{
	  padding: 40px 35px;
	  text-align: center;
	  @media(max-width:1200px){
		  padding: 25px 15px;
	  }

	  h2{
		  font-size: 60px;
		  font-weight: 500;

		  @media(max-width:550px){
			font-size: 30px;
		  }

		  @media(max-width:450px){
				font-size: 25px;
			}
	  }
	  p{
		  font-size: 20px;
		  color: darken($theme-primary-color-s2, 9%);

		  @media(max-width:550px){
			font-size: 16px;
			margin-bottom: 5px;
		  }
	  }

	  ul{
		  list-style: none;
		  max-width: 260px;
		  margin: auto;


		  li{
			  margin-bottom: 15px;
			  font-size: 18px;
			  line-height: 30px;
			  margin-left: 0;
			  float: none;
			  color: #7b7b7b;
			  @media(max-width:550px){
				font-size: 14px;
				margin-bottom: 5px;
			  }

			  @media(max-width:450px){
					margin-bottom: 2px;
				}

			  &:last-child{
				  margin-bottom: 0;
			  }

			  button{
				font-weight: 700;
				color: #8facc2;
				position: relative;
				display: inline-block;
				margin-top: 20px;
				text-transform: capitalize;
				font-size: 16px;

				&:before{
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1px;
					content: "";
					background: #8facc2;
				}

				&:focus{
					outline: none;
					box-shadow: none;
				}

				&:hover{
					background: none;
				}
			}
		  }
	  }
  }
}
}


/*-------------------------------------------
wpo-hero-slider-s3
--------------------------------------------*/

.wpo-hero-slider-s3{
	.slide-inner{
		.slide-content{
			background: $white;
			max-width: 620px;
			margin: unset;
			position: relative;
			z-index: 1;
			@media(max-width:767px){
				padding: 50px 40px;
			}
			
			@media(max-width:1400px){
				right: -100px;
			}

			@media(max-width:1200px){
				margin: 0 auto;
				right: 0;
			}
			@media(max-width:575px){
				padding: 40px 20px;
			}
			.slide-btns {
				@media(max-width:991px){
					margin: 0 auto;
				}	
			}


			.site-border{
				position: absolute;
				left: 20px;
				top: 20px;
				width: 94%;
				height: 90%;
				border: 1px solid $theme-primary-color;
				content: "";
				z-index: -1;

				@media(max-width:767px){
					left: 15px;
					top: 15px;
				}
				@media(max-width:450px){
					left: 10px;
				}
			}
			

			&:after,&:before{
				display: none;
			}

			h2{
				color: $dark-gray;
				font-size: 50px;
				font-weight: 600;
				margin-bottom: 20px;

				@media(max-width:767px){
					font-size: 32px;
					margin-bottom: 10px;
				}
				@media(max-width:575px){
					font-size: 25px;
				}
			}

			p{
				color: $text-color;
				font-size: 22px;
				line-height: 40px;

				@media(max-width:767px){
					font-size: 20px;
					line-height: 30px;
					margin-bottom: 15px;
				}

				@media(max-width:450px){
					font-size: 15px;
				}
			}
		}
	} 
}


/*-------------------------------------------
static-hero-s4
--------------------------------------------*/

.static-hero-s4{
	@include media-query(600px) {
		min-height: 1050px;
	}

}
