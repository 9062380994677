.our-story-image {
  width: 275px  ;
  height: 275px ;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  padding: 30px;
}

.areal-flower {
  transform: rotate(-15deg);
}

.hero-couple {
  background-image: url("../images/couple/couple_500.png");
  @media (min-width: 750px) {
    background-image: url("../images/couple/couple_750.png");
  }
  @media (min-width: 1012px) {
    background-image: url("../images/couple/couple_1012.png");
  }
  @media (min-width: 1350px) {
    background-image: url("../images/couple/couple_1350.png");
  }
  @media (min-width: 1700px) {
    background-image: url("../images/couple/couple_1700.png");
  }
  @media (min-width: 2200px) {
    background-image: url("../images/couple/couple_2200.png");
  }
}

.portofolio-images-container {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  padding-bottom: 16px;
  @media (min-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1012px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1350px) {
    grid-template-columns: repeat(4, 1fr);
  
  }
  /* @media (min-width: 1700px) {
    grid-template-columns: repeat(5, 1fr);
  } */
}

.portofolio-image .thumbnail img{
  cursor: pointer;
  @media (min-width: 750px) {
    height: 300px;  
  }
  /* @media (min-width: 1012px) {
    height: 300px;
  }
  @media (min-width: 1350px) {
    height: 300px;
  }
  @media (min-width: 1700px) {
    width: 20%;
  }
  @media (min-width: 2200px) {
    width: 16.6667%;
  } */
}

.portofolio-images-container .thumbnail {
  display: flex;
  justify-content: center;
}


.story-shape-wrapper {
  position: absolute;
  top: 0px;
  left: -5px;
}

.story-image {
  max-width: 330px;
  padding: 25px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  background: #fff;
  margin: 0 auto;
}

.story-image::before {
  position: absolute;
  left: -103px;
  top: 195px;
  width: 90%;
  height: 90%;
  border: 1px dashed #5E94A6;
  content: "";
  border-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  z-index: -1;
  border-bottom: 0;
  border-right: 0;
  border-top: 0;
  transform: rotate(66deg);
}

.story-image.left-site::before {
  position: absolute;
  left: auto;
  right: -102px;
  transform: rotate(102deg);
}

.story-image img {
  border-radius: 50%;
}